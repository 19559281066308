@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");

:root {
  --white: #fff;
  --editor-box-shadow: rgba(14, 44, 64, 0.15);
  --editor-hover-color: #06c;
  --font-family-work-sans: "Work Sans", sans-serif;
  --quill-tooltip-zIndex: 6
}

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}


a {
  text-decoration: none;
  color: inherit
}

.hide {
  display: none !important;
}


